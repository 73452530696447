const INTER = [{ space_src: 'baidujs', space_type: 'inters', space_id: 'u6919010', space_js: '//acjfpbd.cjdigit.com/production/ezyh/source/ih/common/f_i/openjs/f.js' }];
const DIALOG = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6919011', space_js: '//acjfpbd.cjdigit.com/common/faz/i/j_i/production/gjj.js' }];
const BANNER = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6919012', space_js: '//acjfpbd.cjdigit.com/site/gb/source/ajkj/production/h_k/static/q.js' }];
const NATIVE = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6919013', space_js: '//acjfpbd.cjdigit.com/production/hc_bkl_ki_lu.js' }];

/** 广告位配置 */
export const SPACE_MAP = {
    Home_inter: INTER,
    Home_dialog: DIALOG,
    Home_banner: BANNER,
    Topic_native: NATIVE,
    Discover_native: NATIVE,
    Hot_native: NATIVE,
    Rank_native: NATIVE,
    TopicDetail_inter: INTER,
    TopicDetail_dialog: DIALOG,
    TopicDetail_banner: BANNER,
    TopicResult_inter: INTER,
    TopicResult_dialog: DIALOG,
    TopicResult_native: NATIVE,
    TopicResult_banner: BANNER,
};
